function validateName(text) {
  return text.length > 0;
}

function validateEmail(text) {
  let emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return text.length > 0 && emailCheck.test(text);
}

function validateMessage(text)  {
  return text.length > 0;
}

function validateContactForm(name, email, message) {
  return validateName(name) && validateEmail(email) && validateMessage(message);
}

export { validateName, validateEmail, validateMessage, validateContactForm }