function SkillBar(props) {

  const theStyle = {
    gridTemplateColumns: `${props.percentage}fr ${100 - props.percentage}fr`
  }

  return(
    <div className="skillbar__wrapper" style={theStyle}>
      <div className="skillbar__left">
        <div className="skillbar__title-bar">
          {props.title}
        </div>
      </div>
      <div className="skillbar__right">
        <div className="skillbar__percentage-bar">
          {props.percentage + '%'}
        </div>
      </div>
    </div>
  )
}

export default SkillBar;