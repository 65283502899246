import React, { useEffect } from 'react'

function Clouds(props) {

  var canvas;

  useEffect(() => {
    canvas = document.getElementById("canvas");

    let cloud = document.createElement("div");
    cloud.className = "cloud cloud-big";
    cloud.style.top = `${parseInt(Math.floor(Math.random() * 94))}%`;
    cloud.style.height = `${parseInt(Math.floor(Math.random() * 3) + 3)}%`;
    cloud.style.animationDuration = `${parseInt(Math.floor(Math.random() * 30) + 50)}s`
    cloud.style.animationDelay = `0s`
    canvas.appendChild(cloud);

    if (document.getElementsByClassName("cloud-small").length < 10) {
      for (let i = 0; i < 30; i++) {
        let cloud = document.createElement("div");
        cloud.className = "cloud cloud-small";
        cloud.style.top = `${parseInt(Math.floor(Math.random() * 97))}%`;
        cloud.style.height = `${parseInt(Math.floor(Math.random() * 1.5) + 1.5)}%`;
        cloud.style.animationDuration = `${parseInt(Math.floor(Math.random() * 30) + 90)}s`
        cloud.style.animationDelay = `${parseInt(Math.floor(Math.random() * 100))}s`
        canvas.appendChild(cloud);
      }
    }

    if (document.getElementsByClassName("cloud-big").length < 10) {
      for (let j = 0; j < 15; j++) {
        let cloud = document.createElement("div");
        cloud.className = "cloud cloud-big";
        cloud.style.top = `${parseInt(Math.floor(Math.random() * 94))}%`;
        cloud.style.height = `${parseInt(Math.floor(Math.random() * 3) + 3)}%`;
        cloud.style.animationDuration = `${parseInt(Math.floor(Math.random() * 30) + 50)}s`
        cloud.style.animationDelay = `${parseInt(Math.floor(Math.random() * 60))}s`
        canvas.appendChild(cloud);
      }
    }

  }, [])

  setInterval(() => {
    //   var cloud = document.getElementsByClassName("cloud-big")[0];
    //   console.log(cloud.getBoundingClientRect().left < -70 ? 'true' :  'false');
    canvas = document.getElementById("canvas");
    var bigs = document.getElementsByClassName("cloud-big");
    for (let i = 0; i < bigs.length; i++) {
      if (bigs[i].getBoundingClientRect().left < -70) {
        bigs[i].remove();
        if (document.getElementsByClassName("cloud-big").length < 30) {
          let cloud = document.createElement("div");
          cloud.className = "cloud cloud-big";
          cloud.style.top = `${parseInt(Math.floor(Math.random() * 94))}%`;
          cloud.style.height = `${parseInt(Math.floor(Math.random() * 3) + 3)}%`;
          cloud.style.animationDuration = `${parseInt(Math.floor(Math.random() * 30) + 50)}s`
          cloud.style.animationDelay = `${parseInt(Math.floor(Math.random() * 5))}s`
          canvas.appendChild(cloud);
        }
      }
    }

    var smalls = document.getElementsByClassName("cloud-small");
    for (let i = 0; i < smalls.length; i++) {
      if (smalls[i].getBoundingClientRect().left < -70) {
        smalls[i].remove();
        if (document.getElementsByClassName("cloud-big").length < 30) {
          let cloud = document.createElement("div");
          cloud.className = "cloud cloud-small";
          cloud.style.top = `${parseInt(Math.floor(Math.random() * 97))}%`;
          cloud.style.height = `${parseInt(Math.floor(Math.random() * 1.5) + 1.5)}%`;
          cloud.style.animationDuration = `${parseInt(Math.floor(Math.random() * 30) + 90)}s`
          cloud.style.animationDelay = `${parseInt(Math.floor(Math.random() * 5))}s`
          canvas.appendChild(cloud);
        }
      }
    }
  }, 1000);

  return (
    <section id="canvas" style={{ position: 'relative' }}>
      <div className="text__container flex flex-fd-c flex-jc-c flex-ai-c">
        <h1 style={{ zIndex: '1000', textShadow: '3px 3px #000000' }}>{props.name}</h1>
        <h3 style={{ zIndex: '1000', textShadow: '2px 2px #000000' }}>{props.text}</h3>
      </div>
      <button className="bottom-button flex flex-ai-c flex-jc-c" onClick={() => props.scrollToElement("about-id")}>
        <i className="fas fa-angle-double-down"></i>
      </button>
    </section>
  )
}

export default Clouds;