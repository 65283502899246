import React, { useRef } from 'react'

function Card(props) {
  const imageRef = useRef(null);

  function onMouseEnter() {
    imageRef.current.classList.remove("fade-in");
    imageRef.current.classList.add("fade-out");
  }

  function onMouseLeave() {
    imageRef.current.classList.add("fade-in");
    imageRef.current.classList.remove("fade-out");
  }

  if(props.wip === "true") {
    return(
      <div className="card" onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
        <img src={props.imageUrl} alt={props.title} className="card__image" ref={imageRef}/>
        <div className="card__details flex flex-fd-c flex-ai-c flex-jc-sa">
          <div className="card__description card__description--wip">{props.description}</div>
        </div>
      </div>
    )
  }
  return(
    <div className="card" onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
      <img src={props.imageUrl} alt={props.title} className="card__image" ref={imageRef}/>
      <div className="card__details flex flex-fd-c flex-ai-c flex-jc-sa">
        <div className="card__description">{props.description}</div>
        <a href="https://beyondbooks.sergiudeaj.ro" target="_blank" rel="noreferrer" className="card__button"><div>Visit Website</div></a>
      </div>
    </div>
  )
}

export default Card;