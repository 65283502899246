import React, {useEffect, useState} from 'react'
import { init } from 'emailjs-com';
import emailjs from 'emailjs-com';

import * as TextValidators from './utils/TextValidators'
import * as MESSAGE_BOX_UTILS from './utils/MessageBoxUtils'

import MessageBoxArea from './globals/MessageBoxArea';
import Clouds from './components/Clouds'
import MorphingPolys from './components/MorphingPolys'
import SkillBar from './components/SkillBar'
import Card from './components/Card'

function App() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  var header, sticky;

  useEffect(() => {
    init("user_hP4YY05ZfpWXA262gqKCl");
    header = document.getElementById("myHeader");
    sticky = header.offsetTop;
    window.onscroll = () => {myFunction()};
  }, [])

  function myFunction() {
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  }

  function hamburgerFunction() {
    var btnHamburger = document.getElementById("hamburger");
    var mobileMenu = document.getElementById("mobile-menu");
    var header = document.getElementById("myHeader");
    if(btnHamburger.classList.contains("hamburger-open")) {
      btnHamburger.classList.remove("hamburger-open");
      mobileMenu.classList.remove("menu-open");
      header.classList.remove("menu-open-after");
    } else {
      btnHamburger.classList.add("hamburger-open");
      mobileMenu.classList.add("menu-open");
      header.classList.add("menu-open-after");
    }
  }

  function sendEmail(event) {
    event.preventDefault();

    if(TextValidators.validateContactForm(name, email, message)) {
      emailjs.sendForm('service_ur40dyf', 'template_wm80uq7', event.target, 'GONmd0U2fYlfa4fBC')
      .then((result) => {
        window.location.reload();
      }, (error) => {
        console.log(error.text);
      });
    } else {
      if(!TextValidators.validateName(name)) {
        MESSAGE_BOX_UTILS.addMessageBox("The name cannot be blank. Please enter a valid name.", "OK")
      }
      if(!TextValidators.validateEmail(email)) {
        MESSAGE_BOX_UTILS.addMessageBox("Invalid email address. Please use a valid email address.", "OK")
      }
      if(!TextValidators.validateMessage(message)) {
        MESSAGE_BOX_UTILS.addMessageBox("The message cannot be blank. Please enter a valid message.", "OK")
      }
    }
  }

  function scrollToElement(id) {
    const element = document.getElementById(id);
    var y = element.getBoundingClientRect().top + window.pageYOffset;
    if(id === "projects" || id === "about-id") y -= 100;
    window.scrollTo({top: y, behavior: 'smooth'})
  }

  return (
    <>
      <main>

        <Clouds name="Sergiu Deaj" text="Full Stack Web Developer" scrollToElement={scrollToElement}/>

        <header id="myHeader">
          <nav className="flex flex-jc-sb flex-ai-c max-width">
            <button onClick={() => scrollToElement("canvas")}>Home</button>

            <div className="links flex flex-ai-c hide-for-mobile">
              <button onClick={() => scrollToElement("about-id")}>About</button>
              <button onClick={() => scrollToElement("projects")}>Projects</button>
              <button onClick={() => scrollToElement("wip")}>Work in Progress</button>
            </div>

            <button onClick={() => scrollToElement("contact-me")} className="cta hide-for-mobile"><div>Contact Me</div></button>

            <button onClick={() => hamburgerFunction()} id="hamburger" className="hamburger hide-for-desktop">
              <span></span>
              <span></span>
              <span></span>
            </button>
          </nav>
          <div id="mobile-menu" className="mobile-menu__container">
            <div className="flex flex-jc-c flex-ai-c flex-fd-c hide-for-desktop">
              <button onClick={() => scrollToElement("about-id")}>About</button>
              <button onClick={() => scrollToElement("projects")}>Projects</button>
              <button onClick={() => scrollToElement("wip")}>Work in Progress</button>
              <button onClick={() => scrollToElement("contact-me")}>Contact Me</button>
            </div>
          </div>
        </header>

        <section id="about">
          <div className="max-width responsive-padding">
            <h1 id="about-id">About</h1>
            <MorphingPolys/>
            <div className="introduction-skills flex">
              <div className="introduction">
                <div className="image__border">
                <img src="https://sergiudeaj.ro/assets/images/me3.jpg" alt="Sergiu Deaj" width="250" className="introduction__image"/>
                </div>
                <h3>Who am I?</h3>
                <p>I'm a full stack developer based in Cluj-Napoca, Romania.</p>
                <p>I graduated from the Faculty of Automation and Computer Science, Technical University of Cluj-Napoca</p>
                <p>Currently working as a Full-Stack Developer.</p>
                {/* <p>If you've got a project in mind, let's talk about it.</p> */}
              </div>
              <div className="skills">
                <SkillBar title="React" percentage="75"/>
                <SkillBar title="Next" percentage="75"/>
                <SkillBar title="JavaScript" percentage="65"/>
                <SkillBar title="Python" percentage="75"/>
                <SkillBar title="Java" percentage="75"/>
                <SkillBar title="HTML" percentage="85"/>
                <SkillBar title="CSS" percentage="80"/>
                <SkillBar title="Spring" percentage="65"/>
                <SkillBar title="Flask" percentage="70"/>
                <SkillBar title="MySQL" percentage="75"/>
                <SkillBar title="PostgreSQL" percentage="75"/>
                <SkillBar title="MongoDB" percentage="65"/>
                <SkillBar title="Git" percentage="70"/>
                <SkillBar title="CI/CD" percentage="65"/>
              </div>
            </div>
          </div>
        </section>

        <div id="divider-top" className="divider-top"></div>

        <section id="projects" className="flex flex-ai-c">
          <div className="max-width responsive-padding">
            <h1>Projects</h1>
            <div className="cards flex flex-jc-c flex-ai-c">
              <Card title="Beyondbooks" 
                    description="Online Bookstore implemented with Java Spring, React, MySQL and RESTful Web Services" 
                    imageUrl="https://sergiudeaj.ro/assets/images/beyondbooks.png"/>
            </div>
          </div>
        </section>

        <div className="divider-bottom"></div>

        <section id="wip" className="flex flex-ai-c">
          <div className="max-width responsive-padding">
            <h1>Work in Progress</h1>
            <div className="cards flex flex-jc-c flex-ai-c">
              <Card title="Beyondbooks" 
                    description="Online Bookstore implemented with Java Spring, React, MySQL and RESTful Web Services" 
                    imageUrl="https://sergiudeaj.ro/assets/images/movieratings.png"
                    wip="true"/>
            </div>
          </div>
        </section>

        <section id="contact-me">
          <div className="max-width responsive-padding">
            <h1>Contact Me</h1>
            <p className="flex flex-jc-c">Have a question or simply want to work together?</p>
            <form onSubmit={sendEmail}>
              <div className="flex flex-fd-c">
                <label htmlFor="">Name</label>
                <input type="text" placeholder="John Smith" name="user_name" onChange={(event) => setName(event.target.value)}></input>
              </div>
              <div className="flex flex-fd-c">
                <label htmlFor="">Email</label>
                <input type="text" placeholder="john.smith@gmail.com" name="user_email" onChange={(event) => setEmail(event.target.value)}></input>
              </div>
              <div className="flex flex-fd-c">
                <label htmlFor="">Message</label>
                <textarea style={{resize: 'none'}} placeholder="Hello..." name="message" onChange={(event) => setMessage(event.target.value)}></textarea>
              </div>
              <button className="cta" style={{float: 'right', cursor: 'pointer'}} type='submit'><div>Submit</div></button>
            </form>
          </div>
        </section>
        <footer>
          <div className="max-width responsive-padding">
            <div className="buttons flex flex-ai-c flex-jc-c">
              <a href="https://www.facebook.com/deaj.sergiu/" target="_blank" rel="noreferrer" className="button flex flex-ai-c flex-jc-c">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.linkedin.com/in/sergiu-deaj-3232bb176/" target="_blank" rel="noreferrer" className="button flex flex-ai-c flex-jc-c">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
            <p>SERGIU DEAJ <span>©2023</span></p>
          </div>
          <button className="top-button flex flex-ai-c flex-jc-c" onClick={() => scrollToElement("canvas")}>
            <i className="fas fa-angle-double-up"></i>
          </button>
        </footer>
      </main>
      <MessageBoxArea/>
    </>
  );
}

export default App;
