import React from 'react'

function MorphingPolys() {

  return(
    <div className="shapes flex flex-jc-sb">
      <div className="shape">
        <div className="shape--large"></div>
        <div className="shape--medium"></div>
        <div className="shape--small">
          <div className="shape__title">Fast</div>
          <div className="shape__text">Lag free interaction and fast load times are my #1 priority.</div>
        </div>
      </div>
      <div className="shape">
        <div className="shape--large"></div>
        <div className="shape--medium"></div>
        <div className="shape--small">
          <div className="shape__title">Responsive</div>
          <div className="shape__text">My layouts are perfect for both big and small devices.</div>
        </div>
      </div>
      <div className="shape">
        <div className="shape--large"></div>
        <div className="shape--medium"></div>
        <div className="shape--small">
          <div className="shape__title">Intuitive</div>
          <div className="shape__text">I love developing easy to use UI/UX</div>
        </div>
      </div>
      <div className="shape">
        <div className="shape--large"></div>
        <div className="shape--medium"></div>
        <div className="shape--small">
          <div className="shape__title">Dynamic</div>
          <div className="shape__text">I enjoy making pages come to life.</div>
        </div>
      </div>
    </div>
  )
}

export default MorphingPolys;